import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-line">
        <div className="footer-fb">facebook/marosétterem</div>
        <div className="footer-phone">+36 27 354 576</div>
      </div>
      {/* <div className="footer-text">Maros Étterem © 2023</div> */}
    </div>
  );
};

export default Footer;
